<template>
  <div class="setting-item s-add-to-show-type">
    <esmp-select
      v-model="localSetting.value"
      :placeholder="localSetting.name"
    >
      <esmp-select-option
        v-for="item in availableDatesList"
        :value="item.value"
        :key="item.value"
      >
        {{ item.text }}
      </esmp-select-option>
    </esmp-select>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy';
import { AVAILABLE_DATES_LIST, GRAPH_CALENDAR_NAME } from '@/constants/calendar';

export default {
  name: 'SDatesToShowType',
  inject: ['globalState'],
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    classType: {
      type: String,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    availableDatesList() {
      if (this.classType === GRAPH_CALENDAR_NAME) {
        return AVAILABLE_DATES_LIST;
      }

      return pickBy(AVAILABLE_DATES_LIST, (i) => !i.onlygGraphCalendar);
    },
  },
};
</script>
